import { Car } from "../models/cars.model";

function getSelfDriveCars(): Array<Car> {
    return [
        {
            "Brand": "Maruti Suzuki",
            "Name": "Alto K10",
            "Charges": [799, 1299, 1500, 2000, 2500],
            "HalfDayCharges": [599, 999, 1200, 1500, 2000],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["50", "100", "150", "200", "250"],
            "ExtraKMCharges": [16, 13, 10, 8, 6],
            "Image": "altok10.jpeg",
            "Details": {
                "Fuel": "Petrol",
                "SeatingCapacity": 4,
                "LuggageCapacity": 2,
                "Transmission": "Manual",
                "Avg/Ltr": "18"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Wagon R",
            "Charges": [999, 1699, 2000, 2500, 3000],
            "HalfDayCharges": [799, 1299, 1500, 2000, 2500],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["50", "100", "150", "200", "250"],
            "ExtraKMCharges": [20, 17, 14, 11, 9],
            "Image": "wagonR.jpeg",
            "Details": {
                "Fuel": "Petrol",
                "SeatingCapacity": 5,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "15"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Wagon R",
            "Charges": [2000],
            "HalfDayCharges": [1500],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [12],
            "Image": "wagonR_new.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "23.56 to 34.05"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ertiga",
            "Charges": [3200],
            "HalfDayCharges": [2500],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["200"],
            "ExtraKMCharges": [16],
            "Image": "ertiga_new.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 7,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "20.3 to 26.11"
            }
        },
        {
            "Brand": "Toyota",
            "Name": "Innova",
            "Charges": [3800],
            "HalfDayCharges": [3000],
            "UOM": "Day",
            "StartTime": "9 AM",
            "EndTime": "9 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli",
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kudal, Maharashtra",
            "isSelfDrive": true,
            "KMIncluded": ["200"],
            "ExtraKMCharges": [18],
            "Image": "innova.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 7,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "12"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Wagon R",
            "Charges": [2200],
            "HalfDayCharges": [2200],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [14],
            "Image": "wagonR_new.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "23.56 to 34.05"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Celerio",
            "Charges": [2200],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["Unlimited"],
            "ExtraKMCharges": [0],
            "Image": "celerio.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "20 to 28.50"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ertiga",
            "Charges": [2700],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [18],
            "Image": "ertiga_new.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 6,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "20.3 to 26.11"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Dzire",
            "Charges": [2500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Navi Mumbai"
            ],
            "Hub": "Airoli, Navi Mumbai",
            "isSelfDrive": true,
            "KMIncluded": ["300"],
            "ExtraKMCharges": [16],
            "Image": "dzire.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "22.41 to 31.12"
            }
        },
    ]
}
export default getSelfDriveCars;