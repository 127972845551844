import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, useParams } from 'react-router-dom';
import * as pickDropConfig from '../config/city.json';
import { useEffect, useState } from 'react';
import Select from "react-dropdown-select";
import getSelfDriveCars from '../config/selfDriveCars';
import * as _ from 'lodash';
import * as charges from '../config/charges.json';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Collapsible from 'react-collapsible';
import { SuperSEO } from 'react-super-seo';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import CarDetails from './carDetails.component';
import { setHours, setMinutes } from 'date-fns';
import { Car } from '../models/cars.model';
import getWithDriverCars from '../config/withDriverCars';


function ServiceFinder() {
    const { serviceType, location } = useParams();
    const
        [cover, setCover] = useState<string>(process.env.PUBLIC_URL + '/image/bg_2.jpg'),
        [isLoading, setLoading] = useState<Boolean>(true),
        [isSelfDrive, setIsSelfDrive] = useState<Boolean>(true),
        [selectedPickup, setPickup] = useState<string>(''),
        [selectedDropoff, setDropoff] = useState<string>(''),
        [pickupPoints, setPickupPoints] = useState<any[]>([]),
        [dropoffPoints, setDropoffPoints] = useState<any[]>([]),
        [totalDays, setTotalDays] = useState<number>(1),
        [pickupDate, setPickupDate] = useState<any>(new Date()),
        [dropoffDate, setDropoffDate] = useState<any>(new Date(new Date().setDate(new Date().getDate() + 1)));

    const onPickupDateChange = (pickupDate: Date | null) => {
        setPickupDate(pickupDate)
        setDropoffDate(new Date(new Date().setDate(pickupDate!?.getDate() + 1)))
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsSelfDrive(serviceType === 'self-driving-car-rental');
        setPickAndDrop(_.startCase(location));
        setCoverImage(_.startCase(location));
        setLoading(true);
    }, [serviceType, location]);

    function getTitle() {
        return `${_.startCase(serviceType)} Services In ${_.startCase(location)}`;
    }

    function setCoverImage(city: any) {
        let coverImage = pickDropConfig[city as keyof typeof pickDropConfig].cover;
        setCover(coverImage);
    }

    function setPickAndDrop(city: any) {
        let pickupPoints: any[] = [], dropoffPoints: any[] = [];
        pickDropConfig[city as keyof typeof pickDropConfig].pickup.forEach((pickup, index) => {
            pickupPoints.push({ id: index, name: pickup })
        });
        pickDropConfig[city as keyof typeof pickDropConfig].dropoff.forEach((pickup, index) => {
            dropoffPoints.push({ id: index, name: pickup })
        });
        setPickupPoints(pickupPoints);
        setPickup(pickupPoints[0].name);
        setDropoffPoints(dropoffPoints);
        setDropoff(dropoffPoints[0].name);
    }

    return (
        <>
            <SuperSEO
                title={`Aapli Gaadi: Best ${getTitle()}`}
                description="Aapli Gaadi is a marketplace for cars on rent originated from the Konkan region of Maharashtra. From short road trips to quick near-city drives for supply pick-up, and regional food runs, we have the cheapest car rental options for all your needs!"
                lang="en"
            />
            <div className="hero-wrap " style={{ backgroundImage: `url('${cover}')` }} data-stellar-background-ratio="0.5">

                <div className="container">
                    <div className="row no-gutters slider-text justify-content-start align-items-center justify-content-center">
                        <div className="col-lg-8 ">
                            <div className="text w-100 text-center mb-md-5 pb-md-5">
                                <h1 className="mb-4">
                                    {
                                        getTitle()
                                    }
                                </h1>
                                <p style={{ fontSize: '18px' }}>
                                    Drive unlimited kilometers starting as low as 799/day onwards with <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="ftco-section ftco-no-pt bg-light">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 featured-top">
                            <div className="row no-gutters">
                                <div className="row bg-primary" style={{ borderRadius: '15px', padding: '20px', minWidth: '100%' }} id="trip_details">
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <label htmlFor="" className="label">Pick-up location</label>
                                        <Select className={'form-control'} values={[]} labelField='name' searchable={true} valueField='name' options={pickupPoints} onChange={(values) => { setPickup(values[0].name) }} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <label htmlFor="" className="label">Drop-off location</label>
                                        <Select className={'form-control'} values={[]} labelField='name' searchable={true} valueField='name' options={dropoffPoints} onChange={(values) => { setDropoff(values[0].name) }} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                        <label htmlFor="" className="label">Pick-up date</label><br />
                                        <DatePicker className={'form-control'} selected={pickupDate} showTimeSelect dateFormat='Pp' onSelect={date => onPickupDateChange(date)} onChange={date => onPickupDateChange(date)} value={pickupDate} minDate={new Date()} />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                                        <label htmlFor="" className="label">Drop-off date</label><br />
                                        <DatePicker className={'form-control'} selected={dropoffDate} showTimeSelect dateFormat='Pp' onSelect={date => setDropoffDate(date)} onChange={date => setDropoffDate(date)} value={dropoffDate} minDate={isSelfDrive ? new Date(new Date().setDate(pickupDate.getDate() + 1)) : new Date()} />
                                    </div>
                                    <div className='col-md-4'></div>
                                    <div className="col-md-4 align-items-center">
                                        <br />
                                        <a href="#cars" className="btn btn-secondary py-3 px-4 form-control" > Rent A Car Now</a>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                                {/* <div className="col-md-8 align-items-center">
                                    <div className="services-wrap rounded-right w-100">
                                        <h3 className="heading-section mb-4">Better Way to Rent Your Perfect Cars</h3>
                                        <div className="row d-flex mb-4">
                                            <div className="col-md-4 d-flex align-self-stretch ">
                                                <div className="services w-100 text-center">
                                                    <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-route"></span></div>
                                                    <div className="text w-100">
                                                        <h3 className="heading mb-2">Choose Your Pickup Location</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex align-self-stretch ">
                                                <div className="services w-100 text-center">
                                                    <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-handshake"></span></div>
                                                    <div className="text w-100">
                                                        <h3 className="heading mb-2">Select the Best Deal</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex align-self-stretch ">
                                                <div className="services w-100 text-center">
                                                    <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-rent"></span></div>
                                                    <div className="text w-100">
                                                        <h3 className="heading mb-2">Reserve Your Rental Car</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="ftco-section ftco-no-pt bg-light" id="cars">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 heading-section text-center  mb-5">
                            <h2 className="mb-2">Available Vehicles</h2>
                        </div>
                    </div>
                    {
                        (isSelfDrive ? getSelfDriveCars() : getWithDriverCars()).length > 0 &&
                        _.filter((isSelfDrive ? getSelfDriveCars() : getWithDriverCars()), (car) => { return car.Locations.indexOf(_.startCase(location)) > -1 }).map((vehicle: Car, idx: number) =>
                            <CarDetails key={`${location}-${serviceType}-${idx}`} vehicle={vehicle} idx={idx} pickupDate={pickupDate} dropoffDate={dropoffDate} selectedPickup={selectedPickup} selectedDropoff={selectedDropoff} isSelfDrive={isSelfDrive} />
                        )
                    }
                </div>
            </section>
        </>
    )
}

export default ServiceFinder